<template lang="pug">
.custom-field-value-list
  fi-table(
    :fields = 'fields'
    :items  = 'valueList'
    fixed
  )
    template(v-slot="{ items }")
      tr(v-for = '(item, index) in items')
        td {{ item.id }}
        td {{ item.classifierCode }}
        td {{ item.classifierValue }}
        td
          b-check(
            v-model = 'item.isEnabled'
            :name   = '$attrs.name'
            :value  = 'true'
          )
        td
          button.btn.btn-sm.btn-link.text-danger.fa-pull-right(@click.prevent = 'removeValue(index)')
            i.far.fa-trash-alt.fa-lg
    template(#custom-footer)
      tr: td(colspan="3")
        .input-group.input-group-sm
          slot(
            name = 'input'
            :item = 'customFieldData'
            :onInput = 'addValue'
          )
            input.form-control.form-control-sm.animated.fadeIn(
              v-model.trim = 'customFieldData.classifierCode'
              :class       = '$attrs.class'
              :placeholder = '$t("classifierCode")'
              type         = 'text'
            )
            input.form-control.form-control-sm.animated.fadeIn(
              v-model.trim = 'customFieldData.classifierValue'
              :class       = '$attrs.class'
              :placeholder = '$t("classifierValue")'
              type         = 'text'
            )
          .input-group-append
            button.btn.btn-sm.btn-primary(
              :disabled      = '!customFieldData.classifierValue'
              :title         = '$t("addNew")'
              @click.prevent = 'addValue'
            )
              i.far.fa-plus-square.fa-lg

</template>

<script>
import { CButton, CListGroup, CListGroupItem } from '@coreui/vue'
import FiTable from '@/components/FiTable'

const customFieldValueModel = {
  id: undefined,
  classifierCode: undefined,
  classifierValue: undefined,
  isEnabled: false
}

export default {
  name: 'custom-field-value-list',

  components: {
    FiTable,
    CListGroup,
    CListGroupItem,
    CButton
  },

  inheritAttrs: false,

  model: {
    prop: 'valueList'
  },

  props: {
    valueList: {
      type: Array,
      required: true
    },
    addLabel: {
      type: String,
      default: ''
    },
    brief: {
      type: Boolean
    }
  },

  data: () => ({
    customFieldData: {
      ...customFieldValueModel
    }
  }),

  computed: {
    fields () {
      return [
        ...Object.keys(customFieldValueModel).map(key => ({ key, label: this.$t(key) })),
        {}
      ]
    }
  },

  methods: {
    addValue () {
      this.$emit('input', [
        ...this.valueList,
        {
          ...this.customFieldData,
          isEnabled: true
        }
      ])
      this.customFieldData = { ...customFieldValueModel }
    },
    removeValue (index) {
      this.$emit(
        'input',
        this.valueList.filter((_, idx) => idx !== index)
      )
    }
  }
}
</script>


<i18n>
en:
  addNew:          "Add new value"
  classifierCode:  "Code"
  classifierKey:   "Key"
  classifierValue: "Value"
  isEnabled:       "Enabled"
et:
  addNew:          "Add new value"
  classifierCode:  "Code"
  classifierKey:   "Key"
  classifierValue: "Value"
  isEnabled:       "Enabled"
ru:
  addNew:          "Add new value"
  classifierCode:  "Code"
  classifierKey:   "Key"
  classifierValue: "Value"
  isEnabled:       "Enabled"
</i18n>
